/* globals jQuery, pxlCookie */ 
/* exported primeModal */

class primeModal {
  constructor( modal ) {
    this.$    = modal;
    this.data = modal.dataset;
    
    this.actions();
    this.trigger();
  }
  actions() {
    var _this = this;
    
    jQuery(document).bind('gform_confirmation_loaded', function() {
      var id = 'modal-'+_this.data.id;
      pxlCookie.create(id, 'completed');
    });
    
    jQuery('.closebutton').on('click', function(e) {
      if ( jQuery(this).attr('href') == '#' ) e.preventDefault();
      jQuery.magnificPopup.close();
      if ( jQuery(this).attr('href') == '#' ) return false;
    });
  }
  open( el = false, options = {} ) {
    var _this = this, args = Object.assign({
      showCloseBtn: false,
      mainClass: 'mfp-fade',
      removalDelay: 300,
      fixedContentPos:true,
      items: {
        src: '#'+this.$.id,
        type: 'inline'
      },
      callbacks: {
        beforeOpen: function() {
          if ( _this.mobile() ) _this.startWindowScroll = window.scrollY;
        },
        open: function() {
          if ( this.currItem.inlineElement[0].querySelector('.scripts') )
            eval(this.currItem.inlineElement[0].querySelector('.scripts').innerHTML);
        },
        close: function() {
          if ( _this.mobile() ) window.scrollTo(0, _this.startWindowScroll);
          _this.snooze();
        }
      }
    }, options);
    
    if ( el ) jQuery(el).magnificPopup(args);
    else jQuery.magnificPopup.open(args);
  }
  trigger() {
    var _this     = this,
        completed = pxlCookie.read('modal-'+this.data.id),
        snooze    = parseInt(this.data.snooze),
        snoozing  = pxlCookie.read('modal-'+this.data.id+'-snooze');
        
    if ( completed != 'completed' && ( snooze === 0 || snoozing === false || snoozing >= snooze ) ) {
      switch (this.data.trigger) {
        case 'click':
          this.open('a[href="#' + this.$.id + '"]');
        break;
        case 'immediately': this.open(); break;
        case 'leave':
          var leave = true;
          if ( this.mobile() ) {
            // No good way to do this yet
          }
          else {
            document.addEventListener('mouseleave', function(e) {
              if ( (e.toElement === null && e.relatedTarget === null) && leave && ! jQuery('.mfp-bg').length ) {
                leave = false;
                _this.open();
              }
            }, false);
          }
        break;
        case 'scroll':
          var pop            = true,
              distance       = this.mobile() ? this.data.mobile : this.data.desktop,
              measurement    = this.mobile() ? this.data.mMeasurement : this.data.dMeasurement,
              viewportHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0),
              pageHeight     = document.documentElement.scrollHeight - viewportHeight;
              
          window.addEventListener('scroll', function() {
            if ( pop ) {
              var pos = (document.documentElement.scrollTop||document.body.scrollTop);
              
              if ( pos > 0 ) {
                switch (measurement) {
                  case '%':
                    var percentage = ( pos / pageHeight) * 100;
                    if ( percentage >= distance ) { _this.open(); pop = false; }
                  break;
                  case 'px':
                    if ( pos >= distance ) { _this.open(); pop = false; }
                  break;
                  case 'vh':
                    var d = distance/100, p = pos / viewportHeight;
                    if ( p >= d ) { _this.open(); pop = false; }
                  break;
                  default:
                }
              }
            }
          });
        break;
        case 'time': 
          var timeout = this.mobile() ? this.data.mobile : this.data.desktop;
          setTimeout(function() { _this.open(); }, timeout);
        break;
        default:
      }
    }
    else if ( snoozing <= snooze ) this.snooze();
  }
  snooze() {
    if ( this.data.snooze !== '0' ) {
      var id   = 'modal-'+this.data.id+'-snooze',
          wait = pxlCookie.read(id);
          
      if ( wait ) {
        wait = parseInt(wait) + 1;
        if ( wait > this.data.snooze ) wait = 1;
      }
      else wait = 1
      
      pxlCookie.create(id, wait, 7);
    }
  }
  
  // Mobile Detection
    mobile() {
      return ( navigator.userAgent.match(/Android/i)
       || navigator.userAgent.match(/webOS/i)
       || navigator.userAgent.match(/iPhone/i)
       || navigator.userAgent.match(/iPad/i)
       || navigator.userAgent.match(/iPod/i)
       || navigator.userAgent.match(/BlackBerry/i)
       || navigator.userAgent.match(/Windows Phone/i)
       ? true : false
      )
    }
}